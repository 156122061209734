import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { deleteWorkspace, manageWorkspacePageEmbeddingSettings, manageWorkspaceSettings } from "src/actions/workspace";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Modal from "src/components/Shared/Modal";
import { H2, H3, H5 } from "src/components/Shared/Text/Headers";
import PillEntry from "src/components/Workspaces/PillEntry";
import WorkspaceDomain from "src/components/Workspaces/Workspace/Settings/WorkspaceDomain";
import WorkspaceIntegrationFilters from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFilters";
import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import useFetch from "src/hooks/useFetch";

const WorkspaceSettings = ({ workspaceId, ...props }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [autoImportDomoUser, setAutoImportDomoUser] = useState(false);
  const [authorizedWorkspaces, setAuthorizedWorkspaces] = useState([]);
  const [useWorkspaceSmtp, setUseWorkspaceSmtp] = useState(false);
  const [useWorkspaceSso, setUseWorkspaceSso] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [disabledSsoAutoUpdated, setDisabledSsoAutoUpdated] = useState(false);
  const [disabledDatasetAutoUpdated, setDisabledDatasetAutoUpdated] = useState(false);

  const navigate = useNavigate();

  // For workspace tag
  const [tag, setTag] = useState("");
  const [tagList, setTagList] = useState([]);

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post", data: { workspace_type: "IFRAME_EMBED" } });

  useEffect(() => {
    setAutoImportDomoUser(props.workspaceDetails?.auto_import_domo_user);
    setAuthorizedWorkspaces(props.workspaceDetails?.authorized_workspaces);
    setUseWorkspaceSmtp(props.workspaceDetails?.use_workspace_smtp);
    setUseWorkspaceSso(props.workspaceDetails?.use_workspace_sso);
    setTagList(props.workspaceDetails?.tags || []);
    setDisabledSsoAutoUpdated(props.workspaceDetails?.disabled_sso_auto_updated || false);
    setDisabledDatasetAutoUpdated(props.workspaceDetails?.disabled_dataset_auto_updated || false);
  }, [props.workspaceDetails]);

  const handleUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: workspaceId, ...updateSettings });
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  const handlePageEmbeddingUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspacePageEmbeddingSettings({ workspace_id: workspaceId, ...updateSettings });
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setAuthorizedWorkspaces(updatedSelectedWorkspaces);
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
  };

  const handleTag = async (e) => {
    if ((e.code === "Enter" || e.code === "Comma" || e.submit === true) && tag.trim()) {
      if (e.submit !== true) {
        e.preventDefault();
      }
      if (tagList.findIndex((tg) => tg.toLowerCase().trim() === tag.toLowerCase().trim()) === -1) {
        const tags = [...tagList, tag.trim()];
        setTagList(tags);
        setTag("");
      }
    }
  };

  return (
    <>
      <Section>
        <div className="grid w-full gap-y-10">
          <div className="grid w-full">
            <H2 margin={false}>Workspace Settings</H2>
            {(authorizeUserComponentAccess(props?.me, workspaceId, "integration", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && (
              <WorkspaceIntegrationsList
                workspaceId={workspaceId}
                workspaceDetails={props.workspaceDetails}
                is_global={false}
              />
            )}
            {props?.me?.type === "admin" && workspaceId && props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
              <EditContainer
                title="Page Embedding"
                subtitle="Select authorized workspaces to embed this content as a navigation page"
                preview={{
                  style: "success",
                  isSuccess: authorizedWorkspaces?.length > 0,
                  text: authorizedWorkspaces?.length > 0 ? `${authorizedWorkspaces?.length} workspace(s) selected` : `No workspace selected`,
                }}
                onSuccess={() => handlePageEmbeddingUpdate({ authorized_workspaces: authorizedWorkspaces })}
                onCancel={() => setAuthorizedWorkspaces(props.workspaceDetails?.authorized_workspaces)}>
                <div className="w-full max-w-2xl flex-shrink">
                  <MultiSelectObject
                    defaultOptions={
                      workspacesLoaded &&
                      workspaces
                        .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                        .map((wrk) => {
                          return { key: wrk._id, value: wrk.name, selected: authorizedWorkspaces?.includes(wrk._id?.toString()), object: wrk };
                        })
                    }
                    searchableFields={["name"]}
                    title="Assign workspaces"
                    onChange={handleSelectedWorkspaces}
                    drowdownWindowMaxWidth={"max-w-72 min-w-72"}
                    dropdownButtonClass={"max-w-72 min-w-72"}
                  />
                </div>
              </EditContainer>
            )}
            {workspaceId && props?.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && (authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "workspace_filter", ["update"])) && (
              <WorkspaceIntegrationFilters workspaceId={workspaceId} />
            )}
            {(authorizeUserComponentAccess(props?.me, workspaceId, "layout", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && activeSSO?.isSSOActive && (
              <EditContainer
                title="Workspace tags"
                subtitle="Add tags for filtering sso data"
                preview={{
                  text: tagList.length ? (
                    <>
                      {tagList.length} tag{tagList.length > 1 && "s"}
                    </>
                  ) : (
                    "No tag"
                  ),
                }}
                onSuccess={() => handleUpdate({ tags: tagList })}
                onCancel={() => setTagList(props.workspaceDetails?.tags || [])}>
                <div className="min-w-[260px] flex-shrink">
                  <div className="mb-4 space-y-4">
                    <div className="w-96">
                      <div className="flex w-full justify-end">
                        <p className="mb-.5 text-xs text-gray-300">Hit return to add tag to collection.</p>
                      </div>
                      <Input
                        type="text"
                        autoComplete="off"
                        name="tag-name"
                        label="Tag"
                        inline={true}
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        onKeyDown={handleTag}
                      />
                    </div>
                    {tagList?.length > 0 && (
                      <div className="flex flex-wrap items-center gap-2">
                        {tagList?.map((tag, index) => {
                          return (
                            <PillEntry
                              index={index}
                              tag={tag}
                              onDelete={removeTag}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </EditContainer>
            )}
            {(authorizeUserComponentAccess(props?.me, workspaceId, "smtp", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && (
              <EditContainer
                title="Email Provider"
                subtitle="Connect an existing SMTP server"
                preview={{
                  style: "success",
                  isSuccess: useWorkspaceSmtp,
                  text: useWorkspaceSmtp ? (
                    <>
                      <CheckIcon className="h-5 w-5" /> Enabled
                    </>
                  ) : (
                    <>
                      <NoSymbolIcon className="h-5 w-5" /> Disabled
                    </>
                  ),
                }}
                onSuccess={() => handleUpdate({ use_workspace_smtp: useWorkspaceSmtp })}
                onCancel={() => setUseWorkspaceSmtp(props.workspaceDetails?.use_workspace_smtp)}>
                <div className="min-w-[260px]">
                  <ToggleHeader
                    title="Allow email provider"
                    subtitle="Workspace smtp will override defaults."
                    position="left">
                    <Toggle
                      checked={useWorkspaceSmtp}
                      onChange={() => {
                        setUseWorkspaceSmtp(!useWorkspaceSmtp);
                      }}
                    />
                  </ToggleHeader>
                </div>
              </EditContainer>
            )}
            {props?.me?.type === "admin" && props?.site?.manage_subdomains && (
              <WorkspaceDomain
                workspaceId={workspaceId}
                workspaceDetails={props.workspaceDetails}
              />
            )}
            {(authorizeUserComponentAccess(props?.me, workspaceId, "sso", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"])) && props?.site?.manage_subdomains && (
              <EditContainer
                title="SSO Provider"
                subtitle="Connect up your company's secure login"
                defaultOptions={{
                  onSuccessButtonVisible: !props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? false : true,
                  onCancelButtonVisible: !props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? false : true,
                }}
                preview={{
                  style: "success",
                  isSuccess: useWorkspaceSso,
                  text: useWorkspaceSso ? (
                    <>
                      <CheckIcon className="h-5 w-5" /> Enabled
                    </>
                  ) : (
                    <>
                      <NoSymbolIcon className="h-5 w-5" /> Disabled
                    </>
                  ),
                }}
                onSuccess={() => handleUpdate({ use_workspace_sso: useWorkspaceSso })}
                onCancel={() => setUseWorkspaceSso(props.workspaceDetails?.use_workspace_sso)}>
                <div className="min-w-[260px]">
                  <ToggleHeader
                    title="Allow SSO provider"
                    subtitle="Workspace SSO will override defaults."
                    position="left">
                    <Toggle
                      disabled={!props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? true : false}
                      checked={useWorkspaceSso}
                      onChange={() => {
                        setUseWorkspaceSso(!useWorkspaceSso);
                      }}
                    />
                  </ToggleHeader>
                  {!props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? (
                    <InformationAlert
                      content="A subdomain needs to be added for SSO functionality to be enabled."
                      type="info"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </EditContainer>
            )}
            {authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) && (
              <EditContainer
                title="Data sync settings"
                subtitle="Control both SSO and dataset sync"
                onSuccess={() => handleUpdate({ disabled_sso_auto_updated: disabledSsoAutoUpdated, disabled_dataset_auto_updated: disabledDatasetAutoUpdated })}
                onCancel={() => {
                  setDisabledSsoAutoUpdated(props.workspaceDetails?.disabled_sso_auto_updated);
                  setDisabledDatasetAutoUpdated(props.workspaceDetails?.disabled_dataset_auto_updated);
                }}>
                <div className="min-w-[260px]">
                  <div className="grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
                    <ToggleHeader
                      title="SSO auto update"
                      subtitle="Allow user's data auto update while login with SSO"
                      position="left">
                      <Toggle
                        checked={!disabledSsoAutoUpdated}
                        onChange={(e) => {
                          setDisabledSsoAutoUpdated(!e);
                        }}
                      />
                    </ToggleHeader>
                  </div>
                  <div className="mt-6 grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
                    <ToggleHeader
                      title="Dataset & bulk upload auto update"
                      subtitle="Allow user's data auto update while import from Dataset and bulk upload"
                      position="left">
                      <Toggle
                        checked={!disabledDatasetAutoUpdated}
                        onChange={(e) => {
                          setDisabledDatasetAutoUpdated(!e);
                        }}
                      />
                    </ToggleHeader>
                  </div>
                </div>
              </EditContainer>
            )}
            {props?.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
              <TextAccordion
                startOpen={true}
                headerTextPosition="left"
                headerText="Advanced details">
                <div className="grid gap-y-6 py-3">
                  <div className="flex flex-col justify-between gap-y-3 md:flex-row">
                    <H5 caption="Automatically imports users that were added inside Domo and not within the portal (syncs every 15min).">Auto import DOMO users</H5>
                    <div className="ml-2">
                      <Toggle
                        checked={autoImportDomoUser}
                        onChange={(e) => {
                          setAutoImportDomoUser(e);
                          handleUpdate({ auto_import_domo_user: e });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </TextAccordion>
            )}
          </div>
          {(authorizeUserComponentAccess(props?.me, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.me, workspaceId, "workspace", ["delete"])) && (
            <div className="mt-8 flex w-full items-center">
              <H3 caption="When deleting a workspace, make sure you extract all needed resources from within it">Delete workspace</H3>
              <Button
                version="secondary"
                className="h-12 border-red-300 text-red-300"
                onClick={() => setDeleteId(workspaceId)}>
                Delete
              </Button>
            </div>
          )}
        </div>
      </Section>
      <Modal
        title={props?.workspaceDetails?.name}
        secondaryTitle="Delete"
        isOpen={deleteId ? true : false}
        onCancel={() => setDeleteId(null)}
        isLoading={deleteIsLoading}
        onSuccess={async () => {
          setDeleteIsLoading(true);
          await props.deleteWorkspace(deleteId);
          navigate("/workspaces");
          setDeleteIsLoading(false);
        }}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this workspace?
          </p>
          <div className="text-gray-400">Once you delete this workspace it's gone for good.</div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { deleteWorkspace, manageWorkspaceSettings, manageWorkspacePageEmbeddingSettings })(WorkspaceSettings);
