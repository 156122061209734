import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { manageUserStatus } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import { H3 } from "src/components/Shared/Text/Headers";
import ProfileImage from "src/components/Users/Edit/ViewProfile/ProfileImage";
import { classNames } from "src/helpers/classNames";

const EditUserForm = ({
  isAdmin,
  setIsAdmin,
  manageUserStatus,
  name,
  password,
  confirm_password,
  email,
  active_status,
  image,
  notifications,
  disabled_dataset_auto_updated = false,
  disabled_sso_auto_updated = false,
  user = {},
  setUser = () => {},
  groups = [],
  customFields = [],
  setCustomFields = () => {},
  setGroups = () => {},
  groupsLoaded = false,
  clearState = 0,
  me,
  ...props
}) => {
  const { id } = useParams();

  const [disableResendEmailButton, setDisableResendEmailButton] = useState(false);
  const [passwordUpdateType, setPasswordUpdateType] = useState("reset-email");
  const [disableUserStatusChange, setDisableUserStatusChange] = useState(false);

  const canEditEmailAndPassword = !!id && (!!me?.default_user || me?._id === id || (["admin", "editor"].includes(me?.type) && user?.type === "user"));

  const userStatusChange = async () => {
    setDisableUserStatusChange(true);

    try {
      await manageUserStatus(user);
    } catch (err) {
    } finally {
      setDisableUserStatusChange(false);
    }
  };

  const sendResendEmail = async () => {
    setDisableResendEmailButton(true);

    try {
      const res = await apiRequest("POST", `/users/:user_id/send-reset-email`, {
        body: {},
        queries: undefined,
        params: { user_id: user?._id },
      });
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableResendEmailButton(false);
    }
  };

  const updateCustomFields = (index, value) => {
    setCustomFields((fields) =>
      fields.map((field, i) =>
        field?._id?.toString() !== index?.toString()
          ? field
          : {
              ...field,
              value,
            },
      ),
    );
  };

  useEffect(() => {
    if (clearState) {
      setDisableResendEmailButton(false);
      setPasswordUpdateType("reset-email");
      setDisableUserStatusChange(false);
    }
  }, [clearState]);

  return (
    <Section>
      <H3 caption="Manage individual account display information.">Edit account</H3>
      <div className="mt-4 flex w-full flex-wrap gap-y-4">
        {/* Photo */}
        <div className="relative flex w-full items-center justify-between gap-5 px-2">
          {useCallback(
            () => (
              <ProfileImage
                user={user}
                image={image}
                setImage={(value) => setUser("image", value)}
                isFormdataValue={true}
              />
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [user._id, image, setUser],
          )()}
          {!["editor", "user"].includes(me?.type) && typeof user?.active_status === "boolean" && (
            <div className="absolute right-1 top-1 flex h-full items-start">
              <div>
                <Button
                  onClick={async () => {
                    await userStatusChange();
                    setUser("active_status", !active_status);
                  }}
                  version="default"
                  disabled={disableUserStatusChange}
                  className={classNames("flex !h-7 w-16 justify-center rounded-full px-2 text-xs font-semibold leading-5", active_status ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
                  {active_status ? "Active" : "Inactive"}
                </Button>
              </div>
            </div>
          )}
        </div>
        {/* Name */}
        <div className="w-full px-2 sm:w-1/2">
          <Input
            name="full-name"
            label="Full name"
            type="text"
            inline={true}
            value={name}
            onChange={(e) => setUser("name", e.target.value)}
          />
        </div>
        {!["editor", "user"].includes(me?.type) && (
          <>
            {/* Email */}
            {canEditEmailAndPassword && (
              <div className="w-full px-2 sm:w-1/2">
                <Input
                  name="email"
                  label="Email"
                  inline={true}
                  value={email}
                  onChange={(e) => setUser("email", e.target.value)}
                />
              </div>
            )}
            {customFields.length > 0 &&
              customFields
                .filter((field) => field.is_required)
                .map((field, i) => (
                  <div
                    className="w-full px-2 sm:w-1/2"
                    key={field._id}>
                    <Input
                      inline={true}
                      name={field.label}
                      label={field.label}
                      value={field.value || ""}
                      onChange={(e) => updateCustomFields(field._id, e.target.value)}
                    />
                  </div>
                ))}
            <div className="mb-4 w-full px-2">
              {customFields?.filter((field) => !field.is_required)?.length > 0 && (
                <>
                  <div className="mb-2 text-base text-gray-400">* Optional attributes</div>
                  <div className="relative grid w-full gap-4 md:grid-cols-2">
                    {customFields
                      .filter((field) => !field.is_required)
                      .map((field) => (
                        <div
                          className="relative w-full"
                          key={field._id}>
                          <Input
                            inline={true}
                            name={field.label}
                            label={field.label}
                            value={field.value || ""}
                            onChange={(e) => updateCustomFields(field._id, e.target.value)}
                          />
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
            <div className="w-full px-2 sm:w-1/2">
              {/* Admin status */}
              <ToggleHeader
                title="Assign admin"
                subtitle="You are assigning admin status by enabling this box."
                position="left">
                <Toggle
                  checked={isAdmin}
                  onChange={(e) => {
                    setIsAdmin(!isAdmin);
                  }}
                />
              </ToggleHeader>
            </div>

            <div className="w-full">
              <EditContainer
                title="Reset password settings"
                subtitle="Control user's password"
                defaultOptions={{
                  onCancelButtonVisible: false,
                  onSuccessButtonVisible: false,
                }}>
                <div className="w-full max-w-[600px]">
                  <div className="flex gap-6">
                    <Button
                      version="default"
                      className={classNames("flex !h-auto w-full flex-col !items-start space-y-2 rounded-md border bg-white px-4 py-4 text-left", passwordUpdateType === "reset-email" ? "border-transparent ring-2 ring-highlightColor ring-offset-2" : "border-gray-200")}
                      onClick={() => setPasswordUpdateType("reset-email")}>
                      <div className="text-base font-semibold text-gray-800">Send a reset password email</div>
                      <div className="text-sm text-gray-400">The email will be sent to the user's email</div>
                    </Button>
                    <Button
                      version="default"
                      className={classNames("flex !h-auto w-full flex-col !items-start space-y-2 rounded-md border bg-white px-4 py-4 text-left", passwordUpdateType === "manual" ? "border-transparent ring-2 ring-highlightColor ring-offset-2" : "border-gray-200")}
                      onClick={() => setPasswordUpdateType("manual")}>
                      <div className="text-base font-semibold text-gray-800">Create password</div>
                      <div className="text-sm text-gray-400">You will be able to create user's password</div>
                    </Button>
                  </div>
                  <div className="mt-4 w-full">
                    {passwordUpdateType === "reset-email" ? (
                      <div className="w-full">
                        <Button
                          version="secondary"
                          disabled={disableResendEmailButton}
                          onClick={sendResendEmail}>
                          Send email
                        </Button>
                      </div>
                    ) : (
                      <div className="flex justify-between gap-2">
                        <div className="w-full sm:w-1/2">
                          <Input
                            autoComplete="new-password"
                            type="password"
                            name="password"
                            label="Password"
                            inline={true}
                            value={password}
                            onChange={(e) => {
                              if (!/\s/.test(e.target.value)) {
                                setUser("password", e.target.value);
                              }
                            }}
                          />
                        </div>
                        {/* Confirm Password */}
                        <div className="w-full sm:w-1/2">
                          <Input
                            autoComplete="new-password"
                            type="password"
                            name="confirm-password"
                            label="Confirm password"
                            inline={true}
                            value={confirm_password}
                            onChange={(e) => {
                              if (!/\s/.test(e.target.value)) {
                                setUser("confirm_password", e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </EditContainer>
              <EditContainer
                title="Data sync settings"
                subtitle="Control both SSO and dataset sync"
                defaultOptions={{
                  onCancelButtonVisible: false,
                  onSuccessButtonVisible: false,
                }}>
                <div className="">
                  <ToggleHeader
                    title="SSO auto update"
                    subtitle="Allow user's data auto update while login with SSO"
                    position="left">
                    <Toggle
                      checked={!disabled_sso_auto_updated}
                      onChange={() => {
                        setUser("disabled_sso_auto_updated", !disabled_sso_auto_updated);
                      }}
                    />
                  </ToggleHeader>
                </div>
                <div className="">
                  <ToggleHeader
                    title="Dataset & bulk upload auto update"
                    subtitle="Allow user's data auto update while import from Dataset and bulk upload"
                    position="left">
                    <Toggle
                      checked={!disabled_dataset_auto_updated}
                      onChange={() => {
                        setUser("disabled_dataset_auto_updated", !disabled_dataset_auto_updated);
                      }}
                    />
                  </ToggleHeader>
                </div>
              </EditContainer>
            </div>
          </>
        )}
      </div>
    </Section>
  );
};
const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageUserStatus })(EditUserForm);
