import { CheckIcon, NoSymbolIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { manageWorkspaceSettings, manageWorkspaceSiteName } from "src/actions/workspace";
import LeftNavigation from "src/assets/svgs/left_navigation.svg";
import TopNavigation from "src/assets/svgs/top_navigation.svg";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import { H2, H4 } from "src/components/Shared/Text/Headers";
import { baseUrl, noLogo, publicImageStorage } from "src/config/host";
import { validateHex } from "src/helpers";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { getSiteData } from "src/helpers/siteHelper";

const WorkspaceLayoutStyles = ({ workspace, setWorkspace = () => {}, ...props }) => {
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];

  const { id } = useParams();
  const [layoutType, setLayoutType] = useState("");
  const [layoutLandingPageId, setLayoutLandingPageId] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [useGlobalSettings, setUseGlobalSettings] = useState(false);
  const [disabledIframeExpansion, setDisabledIframeExpansion] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteNameButtonIsDisabled, setSiteNameButtonIsDisabled] = useState(false);
  const [updatedSite, setUpdatedSite] = useState({});
  const [colorSaveButtonDisabled, setIsColorSaveButtonDisabled] = useState(false);

  const options = [
    {
      value: "LEFT_NAVIGATION",
      key: (
        <div className="flex h-7 items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor"
            src={LeftNavigation}
            alt="left navigation"
          />
          <p className="text-xl">Vertical</p>
        </div>
      ),
    },
    {
      value: "TOP_NAVIGATION",
      key: (
        <div className="flex h-7 items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor hover:text-highlightColor"
            src={TopNavigation}
            alt="top navigation"
          />
          <p className="text-xl">Horizontal</p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const setUser = async () => {
      await props.getAuthorizedUser();
    };
    setUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspace?.layout_type) setLayoutType(workspace?.layout_type);
  }, [workspace?.layout_type]);

  useEffect(() => {
    setLayoutLandingPageId(workspace?.layout_landing_page_id);
  }, [workspace?.layout_landing_page_id]);

  useEffect(() => {
    setUseGlobalSettings(workspace?.use_global_settings);
  }, [workspace?.use_global_settings]);

  useEffect(() => {
    setDisabledIframeExpansion(workspace?.disabled_iframe_expansion || false);
  }, [workspace?.disabled_iframe_expansion]);

  useEffect(() => {
    setSiteName(props?.workspaceDetails?.site_name);
  }, [props?.workspaceDetails?.site_name]);

  useEffect(() => {
    if (workspace?._id) {
      setUpdatedSite(getSiteData(workspace, "all"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?._id]);

  const handleStylesDataChange = (keyValue = {}) => {
    setButtonIsDisabled(false);
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleSquareLogoStylesDataChange = (keyValue = {}) => {
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleStylesUpdate = async (body) => {
    let updatedValues = body ? { ...body, name: props.workspaceDetails?.name } : updatedSite;
    try {
      setButtonLoader(true);

      if (updatedValues?.max_width && !/^(\d{1,2}(\.\d+)?%|100%|\d+(?:\.\d+)?px)$/.test(updatedValues.max_width)) {
        toast.error("Please enter a valid max width. For example, 100% or 100px.");
        setButtonLoader(false);
        return;
      }

      if (updatedValues.image_favicon !== workspace.image_favicon) {
        // await apiRequest("DELETE", `/workspaces/:workspace_id/settings/images/:image_id`, {params: {workspace_id: updatedValues._id, image_id: "image_favicon"}});
      }
      if (updatedValues.image_logo !== workspace.image_logo) {
        // await apiRequest("DELETE", `/workspaces/:workspace_id/settings/images/:image_id`, {params: {workspace_id: updatedValues._id, image_id: "image_logo"});
      }
      let payload = {
        ...updatedValues,
        workspace_id: updatedValues._id,
        layout_type: layoutType,
      };

      if (layoutType === "NO_NAVIGATION" && workspace.workspace_type === "IFRAME_EMBED") {
        if (!layoutLandingPageId) {
          toast.error('You must select a landing page when "No Navigation" is selected');
          return;
        }
        payload.layout_landing_page_id = layoutLandingPageId;
      }

      const message = await props.manageWorkspaceSettings({
        workspace_id: updatedValues._id,
        ...updatedValues,
        layout_type: layoutType,
        disabled_iframe_expansion: layoutType === "TOP_NAVIGATION" ? true : disabledIframeExpansion,
        layout_landing_page_id: layoutLandingPageId,
      });

      setWorkspace();

      setButtonLoader(false);
      toast.success(message);
    } catch (error) {
      setButtonLoader(false);
      toast.error(error.message);
    } finally {
      setButtonIsDisabled(true);
    }
  };

  const handleStylesUpdateSquareLogo = async (body) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: body._id, ...body });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSquareLogoClear = () => {
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData(workspace, "square_logo"),
    });
  };

  const ImageHandler = ({ image = "", layout = "square" }) => {
    const [currentImage, setCurrentImage] = useState(null);
    const [imageUrl, setImageUrl] = useState();

    const areFilesEqual = (file1, file2) => {
      // Check if both files have the same name, size, and type
      if (!(file1 instanceof File) || !(file2 instanceof File)) return false;

      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type && file1.lastModified === file2.lastModified;
    };

    let imageSource = useMemo(() => {
      if (image instanceof File && areFilesEqual(image, currentImage)) {
        return imageUrl;
      }

      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
        setImageUrl(objUrl);
        setCurrentImage(image);
      }

      return image instanceof File ? objUrl : image?.includes(publicImageStorage) ? image + "?" + Date.now() : image?.includes("data:image/") ? image : baseUrl + image;
    }, [image, currentImage, imageUrl]);

    const imageComponent = useCallback(() => {
      return (
        <div className={`group relative cursor-pointer ${layout === "rectangle" ? "w-40" : "w-20"} h-20 rounded-md border border-gray-200/60 bg-gray-300/20 hover:border-highlightColor`}>
          {updatedSite._id && (
            <img
              src={imageSource}
              alt="Logo"
              className="h-full w-full rounded-lg object-contain"
              onError={(e) => (e.target.src = noLogo)}
            />
          )}
          <>
            <label
              htmlFor={`change_logo_square_logo`}
              className="absolute left-0 top-0 z-40 h-full w-full cursor-pointer">
              <div className="absolute bottom-0 left-0 flex h-10 w-full cursor-pointer items-center justify-center gap-x-2 rounded-b bg-gray-700/60 px-2 py-1 text-lg text-white transition-all duration-200 group-hover:bg-highlightColor">
                <PhotoIcon className="h-6 w-6 stroke-2" />
                Edit
              </div>
            </label>
            <input
              type="file"
              id={`change_logo_square_logo`}
              name={`change_logo_square_logo`}
              className="hidden"
              onChange={(e) => onImageChange(e)}
            />
          </>
          {imageSource && (
            <div
              className="absolute -right-3 -top-3 z-40 hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 text-center transition-all hover:bg-gray-300 group-hover:flex"
              onClick={() => {
                handleSquareLogoStylesDataChange({ [`square_logo`]: "" });
                setWorkspace((workspace) => ({ ...workspace, square_logo: null }));
              }}>
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </div>
          )}
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource, layout, props.user, updatedSite._id]);

    return imageComponent();
  };

  const onImageChange = (e, type) => {
    let changeType = "square_logo";
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        handleSquareLogoStylesDataChange({ [changeType]: e.target.files[0] });

        setWorkspace((workspace) => ({
          ...workspace,
          [changeType]: e.target.files[0],
        }));
      }
    }
  };

  const ImageHandlerMemoized = useMemo(() => <ImageHandler image={updatedSite.square_logo} />, [updatedSite.square_logo]);

  const onClear = (type = "all") => {
    setIsColorSaveButtonDisabled(false);
    setButtonIsDisabled(true);
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData(workspace, type),
    });
  };

  const handleSiteName = async () => {
    try {
      setSiteNameButtonIsDisabled(true);
      const message = await props.manageWorkspaceSiteName({
        workspace_id: id,
        site_name: siteName,
      });
      setSiteNameButtonIsDisabled(false);
      toast.success(message);
    } catch (error) {
      setSiteName(props?.workspaceDetails?.site_name);
      setSiteNameButtonIsDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setUpdatedSite((prevData) => ({ ...prevData, name: props.workspaceDetails?.name }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workspaceDetails?.name]);

  useEffect(() => {
    const isValid = Object.keys(updatedSite).every((key) => {
      if (key.includes("color") && updatedSite[key]) {
        return validateHex(updatedSite[key]);
      }
      return true;
    });

    setIsColorSaveButtonDisabled(!isValid);
  }, [updatedSite]);

  return (
    <Section>
      <div className="">
        <H2 margin={false}>Layout and Styles</H2>
        {updatedSite?._id && (
          <>
            {authorizeUserComponentAccess(props?.me, id, "alias", ["update"]) && (
              <EditContainer
                title="Site Name"
                preview={{ text: siteName ? siteName : "No site alias found." }}
                defaultOptions={{
                  onSuccessLoaderVisible: siteNameButtonIsDisabled,
                }}
                onSuccess={handleSiteName}
                onCancel={() => setSiteName(props?.workspaceDetails?.site_name)}>
                <div className="min-w-[260px]">
                  <Input
                    name="site-name"
                    label={"Site Name"}
                    inline={true}
                    value={siteName}
                    onChange={(e) => setSiteName(e.target.value)}
                  />
                </div>
              </EditContainer>
            )}
            {authorizeUserComponentAccess(props?.me, id, "layout", ["update"]) && (
              <>
                <EditContainer
                  title="White Label"
                  preview={{
                    style: "success",
                    isSuccess: !useGlobalSettings,
                    text: useGlobalSettings ? (
                      <>
                        <NoSymbolIcon className="h-5 w-5" /> Disabled
                      </>
                    ) : (
                      <>
                        <CheckIcon className="h-5 w-5" /> Enabled
                      </>
                    ),
                  }}
                  onSuccess={handleStylesUpdate}
                  onCancel={() => onClear("white_label")}>
                  <div className="min-w-[260px]">
                    <ToggleHeader
                      title="Allow white labeling"
                      subtitle="Workspace styles will override defaults."
                      position="left">
                      <Toggle
                        checked={!useGlobalSettings}
                        onChange={() => {
                          setUseGlobalSettings(!useGlobalSettings);
                          handleStylesDataChange({ use_global_settings: !useGlobalSettings });
                        }}
                      />
                    </ToggleHeader>
                  </div>
                </EditContainer>
                <EditContainer
                  title="Square Logo"
                  preview={{
                    custom: updatedSite._id && (
                      <div className="flex h-20 w-20 items-center justify-center rounded border">
                        {updatedSite._id && (
                          <img
                            src={updatedSite.square_logo instanceof File ? URL.createObjectURL(updatedSite.square_logo) : updatedSite.square_logo}
                            alt="Logo"
                            className="h-full w-full rounded-lg object-contain"
                            onError={(e) => (e.target.src = noLogo)}
                          />
                        )}
                      </div>
                    ),
                    border: false,
                  }}
                  onSuccess={() => handleStylesUpdateSquareLogo({ _id: updatedSite._id, square_logo: updatedSite.square_logo })}
                  onCancel={onSquareLogoClear}
                  defaultOpen={!updatedSite.square_logo}>
                  <>
                    {/* {!siteOnly && ( */}
                    <div className="flex items-end justify-between">
                      <div className="flex-shrink">
                        <div className="text-base font-semibold text-gray-700">Used for dropdowns and buttons.</div>
                        <div className="mb-2 text-sm font-medium text-gray-400">Recomended image ratio is 1x1</div>
                        <div className="flex gap-x-7">
                          {ImageHandlerMemoized}
                          {/* <div className="flex items-center space-x-2 ml-auto">
                    <Button onClick={() => handleStylesUpdateSquareLogo({ _id: updatedSite._id, square_logo: updatedSite.square_logo })}>Upload</Button>
                    <Button
                    version="gray"
                    onClick={onSquareLogoClear}>
                    Undo
                    </Button>
                  </div> */}
                        </div>
                        {!updatedSite.square_logo && (props?.me?.workspaces?.length > 1 || props?.me?.type === "admin") && (
                          <InformationAlert
                            content="Add a unique logo to tell workspaces apart"
                            type="info"
                          />
                        )}
                      </div>
                    </div>
                    {/* )} */}
                  </>
                </EditContainer>
                {workspace.workspace_type === "IFRAME_EMBED" && (
                  <EditContainer
                    title="Layout"
                    preview={{ text: options[options.findIndex((option) => option.value === layoutType)]?.key, border: false }}
                    onSuccess={handleStylesUpdate}
                    onCancel={onClear}>
                    <>
                      <H4
                        caption="*Options for this workspace's end user experience."
                        margin={false}>
                        Select orientation
                      </H4>
                      <div className="mb-4 mt-1 w-56">
                        <SelectMenu
                          size="md"
                          options={options}
                          startIndex={options.findIndex((option) => option.value === layoutType)}
                          setOption={(option) => {
                            setLayoutType(option.value);
                            handleStylesDataChange({ layout_type: option.value, disabled_iframe_expansion: option.value === "TOP_NAVIGATION" ? true : disabledIframeExpansion });
                            setDisabledIframeExpansion(option.value === "TOP_NAVIGATION" ? true : disabledIframeExpansion);
                          }}
                        />
                      </div>
                      {layoutType === "LEFT_NAVIGATION" && (
                        <div className="min-w-[260px]">
                          <ToggleHeader
                            title="Allow vertical iframe expansion"
                            subtitle="Embedded iframe will expand to the size of the internal content to allow for scrolling in the margins."
                            position="left">
                            <Toggle
                              checked={!disabledIframeExpansion}
                              onChange={() => {
                                setDisabledIframeExpansion(!disabledIframeExpansion);
                                handleStylesDataChange({ disabled_iframe_expansion: !disabledIframeExpansion });
                              }}
                            />
                          </ToggleHeader>
                        </div>
                      )}
                    </>
                  </EditContainer>
                )}
                <EditContainer
                  title="Colors"
                  preview={{ text: "Customize this workspace's themes and styles." }}
                  onSuccess={handleStylesUpdate}
                  onCancel={onClear}
                  defaultOpen={true}
                  fullWidth={true}
                  isDisabled={colorSaveButtonDisabled}
                  isLoading={buttonLoader}
                  borderBottom={false}>
                  <div className="mt-8">
                    <StylesBuilder
                      data={workspace}
                      propsButtonOption={{
                        buttonLoader: buttonLoader,
                        buttonIsDisabled: buttonIsDisabled,
                      }}
                      setButtonIsDisabled={setButtonIsDisabled}
                      updatedSite={updatedSite}
                      setUpdatedSite={setUpdatedSite}
                      handleStylesDataChange={handleStylesDataChange}
                      title="Layout & Styles"
                      secondaryTitle="(Colors, logo, favicon, etc.)"
                      layoutType={layoutType}
                      setWorkspace={setWorkspace}
                    />
                  </div>
                </EditContainer>
              </>
            )}
          </>
        )}
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser, manageWorkspaceSettings, manageWorkspaceSiteName })(WorkspaceLayoutStyles);
