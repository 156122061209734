import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPages } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import ActivityEntry from "src/components/Activity/ActivityEntry";
import DateSearch from "src/components/Activity/DateSearch";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import MultiSelectObjectFilter from "src/components/Shared/Table/MultiRowSelect/MultiSelectObjectFilter";
import SortOrder from "src/components/Shared/Table/SortOrder";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import { formatDate } from "src/helpers/formatDate";
import useFetch from "src/hooks/useFetch";
import DataExport from "src/components/Shared/DataExport";

const ActivityList = ({ me, ...props }) => {
  const activityTypes = [
    { key: "LOGIN", value: "Logins/Authentication" },
    { key: "PAGE_VIEW", value: "Page View" },
    { key: "WORKSPACE_CREATE", value: "Workspace Create" },
    { key: "WORKSPACE_ORDERING", value: "Workspace Ordering" },
    { key: "PAGE_CREATE", value: "Page Create" },
    { key: "PAGE_UPDATE", value: "Page Update" },
    { key: "PAGE_PREVIEW", value: "Page Preview" },
    { key: "PAGE_DELETE", value: "Page Delete" },
    { key: "FORM_CREATE", value: "Form Create" },
    { key: "FORM_UPDATE", value: "Form Update" },
    { key: "FORM_DELETE", value: "Form Delete" },
    { key: "FORM_SETTINGS_UPDATE", value: "Form Settings Update" },
    { key: "FORM_DATASET_UPDATE", value: "Form Dataset Update" },
    { key: "FORM_SUBMIT", value: "Form Submit" },
    { key: "FORM_RESPONSE_PUSH_TO_DATASET", value: "Form Responses Push To Dataset" },
    { key: "USER_CREATE", value: "User Create" },
    { key: "USER_CREATE_BY_SSO", value: "User Created By SSO" },
    { key: "USER_UPDATE", value: "User Update" },
    { key: "USER_UPDATE_BY_SSO", value: "User Updated By SSO" },
    { key: "USER_PASSWORD_CHANGE", value: "User Password Change" },
    { key: "USER_SEND_RESET_PASSWORD_EMAIL", value: "User Send Reset Password Email" },
    { key: "USER_SEND_WELCOME_EMAIL", value: "User Send Welcome Email" },
    { key: "USER_ASSIGN_TO_WORKSPACE", value: "User Assign To Workspace" },
    { key: "USER_UNASSIGN_FROM_WORKSPACE", value: "User Un-Assign From Workspace" },
    { key: "USER_DOMO_ROLE_CHANGE", value: "User Domo Role Change" },
    { key: "USER_INDIVIDUAL_PERMISSION_UPDATE", value: "User Individual Permission Change" },
    { key: "USER_ASSIGN_TO_GROUP", value: "User Assign To Group" },
    { key: "USER_UNASSIGN_TO_GROUP", value: "User Un-Assign To Group" },
    { key: "USER_DATA_ACCESS_UPDATE", value: "User Data Access Update" },
    { key: "USER_ASSIGN_TO_ADMIN", value: "User Assign To Admin" },
    { key: "ADMIN_ASSIGN_TO_USER", value: "Admin Assign To User" },
    { key: "USER_SSO_AUTO_UPDATE_DISABLE", value: "User SSO Auto Update Disabled" },
    { key: "USER_SSO_AUTO_UPDATE_ENABLE", value: "User SSO Auto Update Enabled" },
    { key: "USER_DATASET_AUTO_UPDATE_DISABLE", value: "User Dataset Auto Update Disabled" },
    { key: "USER_DATASET_AUTO_UPDATE_ENABLE", value: "User Dataset Auto Update Enabled" },
    { key: "USER_STATUS_CHANGE_TO_ACTIVE", value: "User Status Change To Active" },
    { key: "USER_STATUS_CHANGE_TO_INACTIVE", value: "User Status Change To In-Active" },
    { key: "USER_DELETE", value: "User Delete" },
    { key: "USER_DELETE_FROM_WORKSPACE", value: "User Delete From Workspace" },
    { key: "GROUP_CREATE", value: "Group Create" },
    { key: "GROUP_UPDATE", value: "Group Update" },
    { key: "GROUP_DELETE", value: "Group Delete" },
    { key: "GROUP_CLONE", value: "Group Clone" },
    { key: "GROUP_ICON_MANAGE", value: "Group Icon Update" },
    { key: "GROUP_ORDERING", value: "Group Ordering" },
    { key: "GROUP_PUBLIC", value: "Group Public" },
    { key: "GROUP_PRIVATE", value: "Group Private" },
    { key: "GROUP_SCHEDULE_UPDATE", value: "Group Schedule Update" },
    { key: "EDITOR_ASSIGN_TO_WORKSPACE", value: "Editor Assign To Workspace" },
    { key: "EDITOR_UNASSIGN_FROM_WORKSPACE", value: "Editor Un-Assign From Workspace" },
    { key: "EDITOR_PERMISSION_UPDATE", value: "Editor Permission Update" },
    { key: "LAYOUT_UPDATE", value: "Layout Update" },
    { key: "SMTP_UPDATE", value: "SMTP Update" },
    { key: "CREDENTIAL_CREATE", value: "Credential Create" },
    { key: "CREDENTIAL_UPDATE", value: "Credential Update" },
    { key: "CREDENTIAL_DELETE", value: "Credential Delete" },
    { key: "CREDENTIAL_MAKE_DEFAULT", value: "Credential Make Default" },
    { key: "CREDENTIAL_SET_CREATED_BY", value: "Credential Set Created By" },
    { key: "WORKSPACE_FILTER_UPDATE", value: "Workspace Filter Update" },
    { key: "WORKSPACE_DOMAIN_CREATE", value: "Workspace Domain Create" },
    { key: "WORKSPACE_DOMAIN_DELETE", value: "Workspace Domain Delete" },
    { key: "WORKSPACE_DELETE", value: "Workspace Delete" },
    { key: "SSO_CREATE", value: "SSO Create" },
    { key: "SSO_UPDATE", value: "SSO Update" },
    { key: "SSO_DELETE", value: "SSO Delete" },
    { key: "SSO_STATUS_CHANGE_TO_ACTIVE", value: "SSO Status Change To Active" },
    { key: "SSO_STATUS_CHANGE_TO_INACTIVE", value: "SSO Status Change To In-Active" },
    { key: "SSO_MAP_TO_WORKSPACE_GROUP_UPDATE", value: "SSO Map To Workspace Group Settings Update" },
    { key: "SSO_MAP_TO_USER_FIELDS_UPDATE", value: "SSO Map To User Fields Settings Update" },
    { key: "USER_CUSTOM_FIELD_CREATE", value: "Custom Attribute Create" },
    { key: "USER_CUSTOM_FIELD_UPDATE", value: "Custom Attribute Update" },
    { key: "USER_CUSTOM_FIELD_MAKE_REQUIRED", value: "Custom Attribute Set Required" },
    { key: "USER_CUSTOM_FIELD_MAKE_OPTIONAL", value: "Custom Attribute Set Optional" },
    { key: "USER_CUSTOM_FIELD_DELETE", value: "Custom Attribute Delete" },
    { key: "SETTINGS_UPDATE", value: "Settings Update" },
    { key: "EMAIL_TEMPLATE_UPDATE", value: "Email template Update" },
    { key: "USER_PUSH_TO_DATASET", value: "Upload Users To Dataset" },
    { key: "USER_PULL_FROM_DATASET", value: "Pull Users From Dataset" },
    { key: "USER_PULL_FROM_BULK_UPLOAD", value: "Upload Bulk Upload" },
    { key: "WORKSPACE_IMPORT_DOMO_USER", value: "Workspace Import Domo User" },
    { key: "ACTIVITY_PUSH_TO_DATASET", value: "Upload Activities To Dataset" },
  ];
  const [sortDirection, setSortDirection] = useState("ASC");

  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [startDate, setStateDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Filters
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedIps, setSelectedIps] = useState([]);

  const handleSelectedItems = (itemsArray, setSelected) => {
    let updatedSelected = itemsArray.reduce((filteredItemsArray, item) => (item.selected ? [...filteredItemsArray, item.key] : filteredItemsArray), []);
    setSelected(updatedSelected);
    setOffset(0);
  };

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const {
    response: { data: ips },
  } = useFetch(`/activities/ip/list`);

  const {
    response: { data: pages },
  } = useFetch(`/pages/list`);

  const {
    response: { data: users },
  } = useFetch(`/users/list`);

  const {
    response: { data: activities, meta: activitiesMeta },
    status: { done: activitiesLoaded },
    refreshData: refreshActivities,
  } = useFetch(`/activities/list-activity`, {
    method: "PUT",
    query: {
      limit: limit,
      offset: offset * limit,
      start_date: startDate,
      end_date: endDate,
      sort_direction: sortDirection,
    },
    data: {
      users: selectedUsers,
      pages: selectedPages,
      activity_types: selectedActivityTypes,
      ip_address: selectedIps,
    },
  });

  useEffect(() => {
    refreshActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, startDate, endDate, selectedUsers, selectedActivityTypes, selectedPages, sortDirection, selectedIps]);

  const exportData = async () => {
    const activityParams = {
      start_date: startDate,
      end_date: endDate,
      sort_direction: sortDirection,
    };
    const activityBody = {
      users: selectedUsers,
      pages: selectedPages,
      ip_address: selectedIps,
      activity_types: selectedActivityTypes,
    };
    try {
      const { data: responseData } = await apiRequest("put", "/activities/list-activity", { body: activityBody, queries: activityParams }, {}, true);
      let csvData = [];
      if (responseData.data.length > 0) {
        for (let index = 0; index < responseData.data.length; index++) {
          const element = responseData.data[index];
          let activity_types = element?.type;
          let typeIndex = activityTypes.findIndex((ty) => ty.key === element?.type);
          if (typeIndex !== -1) {
            activity_types = activityTypes[typeIndex].value;
          }
          csvData.push({
            Email: element?.user_id?.email,
            Name: element?.user_id?.name,
            "Activity Type": activity_types,
            Workspace: element?.data?.workspace_id?.name,
            "Page Accessed": element?.data?.page_id?.name,
            Date: formatDate(element?.created_at, "LLL"),
          });
        }
      }
      return csvData;
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <DateSearch
            setStateDate={setStateDate}
            setEndDate={setEndDate}
            inline={true}
            placeholder={"Select dates"}
          />
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              <div className="flex items-center gap-x-1">
                <p>Name</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    xPlacement="bottomLeft"
                    filterSelect={true}
                    defaultOptions={users
                      ?.filter((user) => me?.default_user || !user?.default_user)
                      ?.map((user) => {
                        return { key: user._id, value: user.name, selected: selectedUsers.includes(user._id), object: user };
                      })}
                    searchableFields={["name", "email"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedUsers);
                    }}
                    itemComponent={(item) => {
                      return (
                        <div className="relative flex flex-col gap-1">
                          <div className="leading-4">{item.name}</div>
                          <div className="text-xs leading-4 text-gray-400">{item.email}</div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              <div className="flex items-center gap-x-1">
                <p>Activity Type</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    xPlacement="bottomLeft"
                    filterSelect={true}
                    defaultOptions={activityTypes.map((type) => {
                      return { key: type.key, value: type.key, selected: selectedActivityTypes.includes(type.key), object: { _id: type.key, ...type } };
                    })}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedActivityTypes);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              <div className="flex items-center gap-x-1">
                <p> Description</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    xPlacement="bottomLeft"
                    filterSelect={true}
                    defaultOptions={pages.reduce((acc, page) => {
                      const isDuplicate = acc.some((option) => option.key === page._id);
                      if (!isDuplicate) {
                        acc.push({ key: page._id, value: page.name, selected: selectedPages.includes(page._id), object: page });
                      }
                      return acc;
                    }, [])}
                    searchableFields={["name"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedPages);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="table-cell rounded-tr-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>Date</p>
                <SortOrder
                  sortDirection={sortDirection}
                  setSortDirection={(value) => {
                    setSortDirection(value);
                    setOffset(0);
                  }}
                />
              </div>
            </th>
            <th
              scope="col"
              className="rounded-tl-lg px-3 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
              <div className="flex items-center gap-x-1">
                <p>Location{me?.default_user && "/IP Address"}</p>
                {me?.default_user && (
                  <div className="flex">
                    <MultiSelectObjectFilter
                      xPlacement="bottomRight"
                      filterSelect={true}
                      defaultOptions={ips?.map((ip) => {
                        return { key: ip._id, value: ip._id, selected: selectedIps.includes(ip._id), object: ip };
                      })}
                      searchableFields={["_id"]}
                      onChange={(e) => {
                        handleSelectedItems(e, setSelectedIps);
                      }}
                    />
                  </div>
                )}
              </div>
            </th>
          </TRHeader>
        }
        colSpan="5"
        loaded={activitiesLoaded}
        dataExists={activities.length > 0}>
        {activities.map((activity) => (
          <ActivityEntry
            key={activity._id}
            activity={activity}
            dataExists={activities.length > 0}
            activityTypes={activityTypes}
          />
        ))}
      </Table>

      <PaginationFooter
        itemName="Activity record"
        limit={limit}
        offset={offset}
        count={activitiesMeta.count}
        onChange={handlePaginationChange}
      />
      {activities.length > 0 && (
        <DataExport
          title="Activity Report"
          headings={["Email", "Name", "Activity Type", "Workspace", "Page Accessed", "Date"]}
          prepData={exportData}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    pages: Object.values(state.pages),
  };
};
export default connect(mapStateToProps, {
  getPages,
})(ActivityList);
