import { validateHex } from "./";
import { noFavicon } from "src/config/host";

export const getSiteData = (data, type = "all") => {
  const defaultColors = {
    top_bar_color: "#FFFFFF",
    top_bar_text_color: "#000000",
    side_bar_color: "#FFFFFF",
    side_bar_text_color: "#000000",
    side_bar_shade_color: "#F5F5F5",
    left_nav_active_text_color: "#000000",
    highlight_color: "#000000",
    background_color: "#FFFFFF",
  };

  const validateColor = (color, defaultColor) => (color && validateHex(color) ? color : defaultColor);

  const commonData = {
    _id: data._id,
    style_key: "workspace_id",
  };

  switch (type) {
    case "all":
      return {
        ...commonData,
        name: data.name,
        image_logo: data.image_logo || "",
        square_logo: data.square_logo || "",
        background_logo: data.background_logo || "",
        image_favicon: data.image_favicon || noFavicon,
        top_bar_color: validateColor(data?.top_bar_color, defaultColors.top_bar_color),
        top_bar_text_color: validateColor(data?.top_bar_text_color, defaultColors.top_bar_text_color),
        side_bar_color: validateColor(data?.side_bar_color, defaultColors.side_bar_color),
        side_bar_text_color: validateColor(data?.side_bar_text_color, defaultColors.side_bar_text_color),
        side_bar_shade_color: validateColor(data?.side_bar_shade_color, defaultColors.side_bar_shade_color),
        left_nav_active_text_color: validateColor(data?.left_nav_active_text_color, defaultColors.left_nav_active_text_color),
        highlight_color: validateColor(data?.highlight_color, defaultColors.highlight_color),
        background_color: validateColor(data?.background_color, defaultColors.background_color),
        use_global_settings: data.use_global_settings || false,
        max_width: data.max_width,
        layout_type: data.layout_type || "LEFT_NAVIGATION",
      };
    case "custom_colors":
    case "layout":
      return {
        ...commonData,
        name: data.name,
        image_logo: data.image_logo || "",
        background_logo: data.background_logo || "",
        image_favicon: data.image_favicon || noFavicon,
        top_bar_color: validateColor(data?.top_bar_color, defaultColors.top_bar_color),
        top_bar_text_color: validateColor(data?.top_bar_text_color, defaultColors.top_bar_text_color),
        side_bar_color: validateColor(data?.side_bar_color, defaultColors.side_bar_color),
        side_bar_text_color: validateColor(data?.side_bar_text_color, defaultColors.side_bar_text_color),
        side_bar_shade_color: validateColor(data?.side_bar_shade_color, defaultColors.side_bar_shade_color),
        left_nav_active_text_color: validateColor(data?.left_nav_active_text_color, defaultColors.left_nav_active_text_color),
        highlight_color: validateColor(data?.highlight_color, defaultColors.highlight_color),
        background_color: validateColor(data?.background_color, defaultColors.background_color),
        max_width: data.max_width,
      };
    case "white_label":
      return {
        ...commonData,
        use_global_settings: data.use_global_settings || false,
      };
    case "square_logo":
      return {
        ...commonData,
        square_logo: data.square_logo || "",
      };
    case "portal_layout":
      return {
        ...commonData,
        layout_type: data.layout_type || "LEFT_NAVIGATION",
      };
    default:
      return {};
  }
};
