import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDetails, updateUser } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditUserForm from "src/components/Users/Edit/ViewProfile/EditUserForm";
import { objectToFormData } from "src/helpers";
import useFetch from "src/hooks/useFetch";

const UserProfileDetailsEdit = ({ groups = [], setGroups = () => {}, fetchUser = () => {}, me, ...props }) => {
  const [updatedUserFields, setUpdatedUserFields] = useState({});

  const [successButtonStart, setSuccessButtonStart] = useState(false);
  const [isAdmin, setIsAdmin] = useState();
  const [initialCustomFields, setInitialCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [clearState, setClearState] = useState(0);

  const { id } = useParams();

  const {
    response: { data: user },
    refreshData: refreshUser,
  } = useFetch(`/users/details`, {
    method: "POST",
    data: { id: useParams().id ?? null },
  });

  useEffect(() => {
    refreshUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (user?.type === "admin") {
      setIsAdmin(true);
    }
  }, [user?.type]);

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setInitialCustomFields(resData.data);
          setCustomFields(resData.data);
        }
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setSuccessButtonStart(true);

    const updateData = {
      ...user,
      type: !isAdmin ? (user.type === "editor" ? "editor" : "user") : "admin",
      ...updatedUserFields,
      custom_fields: JSON.stringify(customFields),
    };

    if (updateData.user_permissions) {
      delete updateData.user_permissions;
    }

    const convertedPayload = objectToFormData(updateData);

    try {
      const message = await props.updateUser(convertedPayload, true);
      setUpdatedUserFields((prevData) => ({ ...prevData, password: "", confirm_password: "" }));
      await fetchUser(id);
      setSuccessButtonStart(false);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      setSuccessButtonStart(false);
    }
  };

  useEffect(() => {
    if (user?.custom_fields?.length && customFields.length) {
      setInitialCustomFields((customFields) =>
        customFields.map((field) => {
          const findField = user.custom_fields.find((item) => item.custom_field_id === field._id);

          if (!findField) {
            return { ...field, value: field.default_value || "" };
          } else {
            return { ...field, value: findField.value || field.default_value || "" };
          }
        }),
      );
      setCustomFields((customFields) =>
        customFields.map((field) => {
          const findField = user.custom_fields.find((item) => item.custom_field_id === field._id);

          if (!findField) {
            return { ...field, value: field.default_value || "" };
          } else {
            return { ...field, value: findField.value || field.default_value || "" };
          }
        }),
      );
    } else if (user?._id && customFields.length) {
      setInitialCustomFields((customFields) =>
        customFields.map((field) => ({
          ...field,
          value: field.default_value || "",
        })),
      );
      setCustomFields((customFields) =>
        customFields.map((field) => ({
          ...field,
          value: field.default_value || "",
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.custom_fields?.length, customFields.length]);

  const handleSetUser = (key, value) => {
    setUpdatedUserFields({ ...updatedUserFields, [key]: value });
  };

  return (
    <>
      <EditUserForm
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        user={user}
        setUser={handleSetUser}
        groups={groups}
        setGroups={setGroups}
        customFields={customFields}
        setCustomFields={setCustomFields}
        clearState={clearState}
        {...user}
        {...updatedUserFields}
      />
      {user?.type === "admin" && me?.type !== "admin" ? (
        <></>
      ) : (
        <div className="mt-5 flex w-full justify-end gap-x-3 pt-3">
          <Button
            version="gray"
            onClick={() => {
              refreshUser();
              setUpdatedUserFields({});
              setCustomFields(initialCustomFields);
              setIsAdmin(user.type === "admin");
              setClearState((state) => state + 1);
            }}>
            Clear all
          </Button>
          <Button
            versions="primary"
            type="button"
            onClick={onSubmit}
            disabled={successButtonStart}
            loading={successButtonStart}>
            Save
          </Button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserDetails, updateUser })(UserProfileDetailsEdit);
