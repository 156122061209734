import { BeakerIcon } from "@heroicons/react/24/outline";

const WorkspaceSubscriberInstance = () => {
  return (
    <div className="-ml-1 w-[100px] inline-flex items-center justify-center gap-x-2 rounded-sm bg-gray-50/80 px-1 py-1 text-sm text-gray-400">
      <BeakerIcon className="h-4 w-4" />
      Full Access
    </div>
  );
};

export default WorkspaceSubscriberInstance;
