import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { manageWorkspaceSettings } from "src/actions/workspace";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import SiteColorItem from "src/components/Site/ThemesAndStyles/SiteColorItem";
import { validateHex } from "src/helpers";

const StylingContent = ({ style = null, setStyle = () => {}, disabled = false, setDisabled = () => {}, onSubmit = () => {}, onCancel = () => {} }) => {
  const [saveBtnDisable, setSaveBtnDisable] = useState(false);
  // Update style
  const updateStyle = (key, value) => {
    setStyle((prevData) => ({ ...prevData, [key]: value }));
    setDisabled(false);
  };

  useEffect(() => {
    const isValid = Object.keys(style).every((key) => {
      if (key.includes("color") && style[key]) {
        return validateHex(style[key]);
      }
      return true;
    });

    setSaveBtnDisable(!isValid);
  }, [style]);

  // Handle style
  const handleStyle = async () => {
    setDisabled(true);
    await onSubmit(style, "settings/sso/button-style");
    setDisabled(false);
  };

  return (
    <EditContainer
      title="SSO Styling"
      preview={{ text: "Configure the SSO button." }}
      onSuccess={handleStyle}
      isLoading={disabled}
      isDisabled={saveBtnDisable}
      onCancel={onCancel}
      defaultOpen={true}
      fullWidth={true}>
      <div className="mt-8 flex w-full justify-center">
        <div className="flex w-96 flex-col gap-x-4 gap-y-6 sm:flex-row md:items-end">
          <div className="mb-10 w-[200px] sm:mb-0">
            <Input
              name="sso-button-text"
              label="Button Text"
              inline={true}
              value={style?.sso_button_text || "SSO Login"}
              onChange={(e) => updateStyle("sso_button_text", e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="flex space-x-4">
              <div
                className="mt-8 flex h-[47px] min-w-[130px] items-center justify-center rounded-md bg-gray-400 px-4 sm:mt-6 md:h-[40px] 2xl:h-[47px]"
                style={{ backgroundColor: style?.sso_button_color || "#000000" }}>
                <p
                  className="flex items-center justify-center rounded-md font-medium"
                  style={{ color: style?.sso_button_text_color || "#FFFFFF" }}>
                  {style?.sso_button_text || "SSO Login"}
                </p>
              </div>
            </div>
            <div className="absolute bottom-14 left-0 z-10 flex w-[160px] flex-col rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-bottom-2 before:left-4 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-l-0 before:border-t-0 before:border-gray-100 before:bg-white before:content-['']">
              <SiteColorItem
                onChange={(e) => updateStyle("sso_button_color", e)}
                color={style?.sso_button_color || "#000000"}
                name="SSO Button Background"
              />
            </div>
            <div className="absolute -bottom-20 left-[20px] z-20 flex w-[160px] flex-col rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-top-2 before:left-4 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-b-0 before:border-r-0 before:border-gray-100 before:bg-white before:content-['']">
              <SiteColorItem
                onChange={(e) => updateStyle("sso_button_text_color", e)}
                color={style?.sso_button_text_color || "#FFFFFF"}
                name="SSO Button Text"
              />
            </div>
          </div>
        </div>
      </div>
    </EditContainer>
  );
};

const NavigationControlContent = ({ workspaceId = null, navigationControl = null, setNavigationControl = () => {}, disabled = false, setDisabled = () => {}, onSubmit = () => {}, onCancel = () => {} }) => {
  // Update navigation control
  const updateNavigationControl = (key, value) => {
    setNavigationControl((prevData) => ({ ...prevData, [key]: value }));
    setDisabled(false);
  };

  // Handle navigation control
  const handleNavigationControl = async () => {
    setDisabled(true);
    await onSubmit(navigationControl, "settings/sso/navigation-control");
    setDisabled(false);
  };

  return (
    <EditContainer
      title={`Navigation Controls`}
      preview={{ text: "Configure the SSO navigation controls." }}
      onSuccess={handleNavigationControl}
      isLoading={disabled}
      onCancel={onCancel}
      defaultOpen={true}
      fullWidth={true}>
      <div className="mt-8 flex w-full flex-col">
        {!workspaceId && (
          <div className="min-w-[260px] px-2">
            <ToggleHeader
              title="Allow dual signin for the Admin portal"
              subtitle="If this is toggled on then Admins may login both with SSO or with a manual email and password. Verify correct SSO setup before toggling."
              position="left">
              <Toggle
                checked={navigationControl?.dual_signin_for_admin}
                onChange={() => updateNavigationControl("dual_signin_for_admin", !navigationControl?.dual_signin_for_admin)}
              />
            </ToggleHeader>
          </div>
        )}
        <div className="min-w-[260px] px-2">
          <ToggleHeader
            title="Allow dual signin for the User portal"
            subtitle="If this is toggled on then Admins may login both with SSO or with a manual email and password."
            position="left">
            <Toggle
              checked={navigationControl?.dual_signin_for_user}
              onChange={() => updateNavigationControl("dual_signin_for_user", !navigationControl?.dual_signin_for_user)}
            />
          </ToggleHeader>
        </div>
        {!workspaceId && (
          <div className="min-w-[260px] px-2">
            <ToggleHeader
              title="Redirect Admin Portal admins to a logout landing page"
              subtitle={`When enabled admins will be redirect to a "You've been logged out" page after a successful logout from SSO.`}
              position="left">
              <Toggle
                checked={navigationControl?.sso_logout_landing_page_redirection_for_admin}
                onChange={() => updateNavigationControl("sso_logout_landing_page_redirection_for_admin", !navigationControl?.sso_logout_landing_page_redirection_for_admin)}
              />
            </ToggleHeader>
          </div>
        )}
        <div className="min-w-[260px] px-2">
          <ToggleHeader
            title="Redirect User Portal user to a logout landing page"
            subtitle={`When enabled users will be redirect to a "You've been logged out" page after a successful logout from SSO.`}
            position="left">
            <Toggle
              checked={navigationControl?.sso_logout_landing_page_redirection_for_user}
              onChange={() => updateNavigationControl("sso_logout_landing_page_redirection_for_user", !navigationControl?.sso_logout_landing_page_redirection_for_user)}
            />
          </ToggleHeader>
        </div>
      </div>
    </EditContainer>
  );
};

const SSOCustomizations = ({ workspaceId = null, site = null, workspaceDetails = null, manageWorkspaceSettings, manageSiteSettings }) => {
  // Style variables
  const [style, setStyle] = useState({
    sso_button_text: "",
    sso_button_color: "",
    sso_button_text_color: "",
  });
  const [isStyleDisabled, setIsStyleDisabled] = useState(false);
  // Navigation control
  const [navigationControl, setNavigationControl] = useState({
    dual_signin_for_admin: false,
    dual_signin_for_user: false,
    sso_logout_landing_page_redirection_for_admin: false,
    sso_logout_landing_page_redirection_for_user: false,
  });
  const [isNavigationControlDisabled, setIsNavigationControlDisabled] = useState(false);

  const onSubmit = async (body, endpoint) => {
    try {
      const message = workspaceId ? await manageWorkspaceSettings({ workspace_id: workspaceId, ...body }) : await manageSiteSettings(body, endpoint);
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const setStyleSettings = () => {
    setStyle({
      sso_button_text: workspaceId ? workspaceDetails.sso_button_text : site.sso_button_text,
      sso_button_color: workspaceId ? workspaceDetails.sso_button_color : site.sso_button_color,
      sso_button_text_color: workspaceId ? workspaceDetails.sso_button_text_color : site.sso_button_text_color,
    });
  };

  const setNavigationControlSettings = () => {
    let dual_signin_for_admin = workspaceId ? false : site.dual_signin_for_admin;
    let dual_signin_for_user = workspaceId ? workspaceDetails.dual_signin_for_user : site.dual_signin_for_user;
    let sso_logout_landing_page_redirection_for_admin = workspaceId ? false : site.sso_logout_landing_page_redirection_for_admin;
    let sso_logout_landing_page_redirection_for_user = workspaceId ? workspaceDetails.sso_logout_landing_page_redirection_for_user : site.sso_logout_landing_page_redirection_for_user;
    setNavigationControl({
      dual_signin_for_admin: dual_signin_for_admin === false ? dual_signin_for_admin : true,
      dual_signin_for_user: dual_signin_for_user === false ? dual_signin_for_user : true,
      sso_logout_landing_page_redirection_for_admin: sso_logout_landing_page_redirection_for_admin === true ? sso_logout_landing_page_redirection_for_admin : false,
      sso_logout_landing_page_redirection_for_user: sso_logout_landing_page_redirection_for_user === true ? sso_logout_landing_page_redirection_for_user : false,
    });
  };

  useEffect(() => {
    setStyleSettings();
    setNavigationControlSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, site, workspaceDetails]);

  return (
    <>
      <StylingContent
        style={style}
        setStyle={setStyle}
        disabled={isStyleDisabled}
        setDisabled={setIsStyleDisabled}
        onSubmit={onSubmit}
        onCancel={setStyleSettings}
      />
      <NavigationControlContent
        workspaceId={workspaceId}
        navigationControl={navigationControl}
        setNavigationControl={setNavigationControl}
        disabled={isNavigationControlDisabled}
        setDisabled={setIsNavigationControlDisabled}
        onSubmit={onSubmit}
        onCancel={setNavigationControlSettings}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageSiteSettings, manageWorkspaceSettings })(SSOCustomizations);
