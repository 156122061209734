import { ShieldCheckIcon, FunnelIcon, RectangleStackIcon, UserIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getUserDetails } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import BackIndicator from "src/components/Navigation/BackIndicator";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import GlobalFiltersList from "src/components/Users/Edit/GlobalFilters/GlobalFiltersList";
import UserPermissions from "src/components/Users/Edit/Permissions/UserPermissions";
import UserProfileDetailsEdit from "src/components/Users/Edit/UserProfileDetailsEdit";
import UserWorkspaceList from "src/components/Users/Edit/Workspaces/UserWorkspaceList";
import UserProfile from "src/components/Users/UserProfile";
import { userWorkspacesAndAssociatedPermissions } from "src/actions/workspace";
import { useSearchParams } from "react-router-dom";

const UserEdit = ({ me, ...props }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [workspaceGroups, setWorkspaceGroups] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [workspaceEditorList, setWorkspaceEditorList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchUser = async (_id) => {
    setUser({});
    try {
      const { data: user } = await apiRequest("post", `/users/details`, { body: { id: _id ?? null } });
      setUser(user.data);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  useEffect(() => {
    if (id) {
      const ac = new AbortController();
      const loadData = async () => {
        try {
          const workspace = await props.userWorkspacesAndAssociatedPermissions({ user_id: id }, ac.signal);
          setWorkspaceEditorList(workspace);
        } catch (error) {
          // toast.error(error.message);
        } finally {
          setLoaded(true);
        }
      };
      loadData();
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Load workspaces
  useEffect(() => {
    if (user?._id) {
      setTabs([
        {
          name: "View Profile",
          icon: UserIcon,
          component: (
            <UserProfileDetailsEdit
              groups={workspaceGroups}
              setGroups={setWorkspaceGroups}
              fetchUser={fetchUser}
            />
          ),
          visible: true,
        },
        { name: "Workspaces", icon: RectangleStackIcon, component: <UserWorkspaceList userDetails={user} />, visible: me?.type === "admin" ? true : false },
        {
          name: "Editors",
          icon: ShieldCheckIcon,
          component: (
            <UserPermissions
              loaded={loaded}
              workspaceList={workspaceEditorList}
              setWorkspaceEditorList={setWorkspaceEditorList}
              userId={id}
            />
          ),
          visible: me?.type === "admin" ? true : false,
        },
        {
          name: "Data Access",
          icon: FunnelIcon,
          component: <GlobalFiltersList />,
          visible: me?.type === "admin" ? true : false,
        },
        // { name: "Settings", icon: Cog8ToothIcon, component: <div>Hello world</div> },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, me?._id, loaded, workspaceEditorList]);

  const redirectToWorkspace = () => {
    if (me?.type === "editor" && Array.isArray(me?.workspaces) && me.workspaces.length === 1) {
      navigate(`/workspaces/${me.workspaces[0]._id}?tab=Users`);
    } else {
      navigate("/" + window.location.pathname.split("/")[1]);
    }
  };

  const navigateToPathname = () => {
    navigate(searchParams.get("pathname"));
  };

  return (
    <>
      <H1
        item={{ icon: BackIndicator, onClick: me?.type === "editor" ? redirectToWorkspace : navigateToPathname }}
        className="flex-wrap gap-y-2 sm:flex-nowrap sm:gap-y-0">
        <p className="font-light capitalize text-gray-300">{user?.type === "admin" ? "Admins" : "Users"} /</p>
        <div className="flex items-center gap-2 pl-10 sm:pl-0">
          <div className="h-10 w-10 overflow-hidden rounded-full border border-gray-100">
            {useCallback(
              () => (
                <UserProfile
                  user={user}
                  size="sm"
                />
              ),

              // eslint-disable-next-line react-hooks/exhaustive-deps
              [user?._id],
            )()}
          </div>
          {user?.name}
        </div>
      </H1>
      <Tabs tabs={tabs.filter((tab) => tab.visible)}></Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails,
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserDetails, userWorkspacesAndAssociatedPermissions })(UserEdit);
